import { App, ref } from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import JwtService from "@/core/services/JwtService"
import { AxiosResponse, AxiosRequestConfig } from "axios"
import router from "@/router"
import { ElNotification } from "element-plus"
import { Store } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import queryString from "query-string"
import { ReportType } from "../enums/enums"
/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App

  public static anonymRoots = ["auth/refresh-token", "auth/reset-password"]

  public static isRefreshing = false

  public static requests: unknown[] = []

  public static apiPath = "/api"

  public static reportPath = "/report"

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>, store: Store<unknown>) {
    ApiService.vueInstance = app
    axios.defaults.withCredentials = true
    ApiService.vueInstance.use(VueAxios, axios)
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_URL

    ApiService.vueInstance.axios.interceptors.request.use(
      async config => {
        store.commit("loader/setLoading", true)
        config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: "none" })

        config.headers = {
          Authorization: `Bearer ${JwtService.getToken()}`,
          Accept: "application/json",
          RootPath: router.currentRoute.value.fullPath,
          ...config.headers,
        }

        if (ApiService.anonymRoots.includes(config.url ?? "")) {
          config.headers = {
            Accept: "application/json",
          }
        }

        return config
      },
      error => {
        Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use(
      response => {
        store.commit("loader/setLoading", false)
        if (response.status === 200 || response.status === 201) {
          if (response.data.message) {
            ElNotification({
              title: "Bilgi",
              message: response.data.message,
              type: "success",
              duration: 2000,
            })
          }
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      },
      async error => {
        store.commit("loader/setLoading", false)
        if (error?.response?.status) {
          switch (error.response.status) {
            case 400: {
              const errHeader = ref("")
              const errText = ref("")

              if (error.response.config.url == "auth/refresh-token") {
                router.replace({
                  path: "/sign-in",
                  //query: { redirect: router.currentRoute.value.fullPath },
                })
              }

              if (error.response.data.title !== undefined) {
                errHeader.value = error.response.data.title

                if (error.response.data.errors !== undefined) {
                  errText.value += "<ul>"
                  for (const [, value] of Object.entries(error.response.data.errors)) {
                    if (Array.isArray(value)) {
                      value.forEach(vel => {
                        errText.value += "<li style='text-align: left;'>" + vel + "</li>"
                      })
                    } else {
                      errText.value += "<li style='text-align: left;'>" + value + "</li>"
                    }
                  }
                  errText.value += "</ul>"
                }
              } else if (error.response.data.message !== undefined) {
                errHeader.value = "Uyarı"
                errText.value = error.response.data.message
              }

              ElNotification({
                title: errHeader.value,
                dangerouslyUseHTMLString: true,
                message: errText.value,
                type: "error",
                duration: 20000,
              })

              break
            }
            case 401:
              store.dispatch(Actions.LOGOUT)
              router.replace({
                path: "/sign-in",
                //query: { redirect: router.currentRoute.value.fullPath },
              })
              break
            case 403:
              router.push({ name: "homepage" })
              break
            case 404:
              if (error.response.data.message) {
                ElNotification({
                  title: "Hata",
                  message: error.response.data.message,
                  type: "error",
                  duration: 12000,
                })
              }
              break
            case 500:
              if (error.response.data !== undefined) {
                ElNotification({
                  title: "Sunucu Hatası!",
                  dangerouslyUseHTMLString: true,
                  message: `<p style="text-align: left;">
                      ${error.response.data.message.replaceAll("\n", "<br />")}
                    </p>`,
                  type: "error",
                  duration: 5000,
                })
              }
              break
            case 502:
              setTimeout(() => {
                router.replace({
                  path: "/login",
                  //query: {
                  //  redirect: router.currentRoute.value.fullPath,
                  //},
                })
              }, 1000)
          }
          return Promise.reject(error.response)
        }
      }
    )
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${this.apiPath}/${resource}`, params)
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(resource: string, slug = "" as string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${this.apiPath}/${resource}/${slug}`)
  }

  public static async report(param: { report: ReportType; payload: string }) {
    try {
      const { data } = await ApiService.vueInstance.axios.post(`${this.reportPath}/set`, param)
      const url = `${process.env.VUE_APP_URL}${
        this.reportPath
      }/get?reportGuid=${data}&jwtToken=${JwtService.getToken()}`
      window.open(url, "_blank")
    } catch (error) {
      console.error("Error occurred while fetching report:", error)
    }
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    params?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${this.apiPath}/${resource}`, data, params)
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${this.apiPath}/${resource}/${slug}`, params)
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(resource: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${this.apiPath}/${resource}`, params)
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(`${this.apiPath}/${resource}`)
  }
}

export default ApiService
