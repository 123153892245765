//TODO: dosyalara ayırmak gerek enum klasörü altında
import { SelectModelInt } from "../models/SelectModel"

export const staticCurrencyList: Currency[] = [
  {
    id: 1,
    name: "TL",
    suffix: "₺",
  },
  {
    id: 2,
    name: "Dolar",
    suffix: "$",
  },
  {
    id: 3,
    name: "Euro",
    suffix: "€",
  },
]

interface Currency {
  id: number
  name: string
  suffix: string
}

export const staticPaymentStatusList: PaymentStatus[] = [
  {
    id: 1,
    name: "Ödendi",
  },
  {
    id: 2,
    name: "Ödenmedi",
  },
]

interface PaymentStatus {
  id: number
  name: string
}

export const staticGenderList: Gender[] = [
  {
    id: 0,
    name: "Tanımsız",
  },
  {
    id: 1,
    name: "Erkek",
  },
  {
    id: 2,
    name: "Kadın",
  },
]

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Gender extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NoteLevel extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContactTag extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RelationShip extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StudentOrderState extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SchoolType extends SelectModelInt {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ServiceType extends SelectModelInt {}

export enum NoteLevelEnum {
  Info = 1,
  Warning,
  Error,
  System,
}

export enum GenderEnum {
  Undefined,
  Male,
  Female,
}

export const staticNoteLevelList: NoteLevel[] = [
  {
    id: 1,
    name: "Bilgi",
  },
  {
    id: 2,
    name: "Uyarı",
  },
  {
    id: 3,
    name: "Önemli",
  },
  {
    id: 4,
    name: "Sistem",
  },
]

export enum ContactTagEnum {
  Primary = 1,
  Mobile,
  Home,
  Work,
  School,
  Whatsapp,
  Fax,
  Other,
}

export const staticContactTagList: ContactTag[] = [
  {
    id: 1,
    name: "Mobil",
  },
  {
    id: 2,
    name: "Ev",
  },
  {
    id: 3,
    name: "İş",
  },
  {
    id: 4,
    name: "Okul",
  },
  {
    id: 5,
    name: "Whatsapp",
  },
  {
    id: 6,
    name: "Fax",
  },
  {
    id: 7,
    name: "Diğer",
  },
]

export enum ContactTypeEnum {
  Phone = 1,
  EmailAddress,
  Address,
}

export enum RelationShipEnum {
  Mother = 1,
  Father,
  Uncle,
  Aunt,
  Grandmother,
  Grandfather,
}

export const staticRelationShipList: RelationShip[] = [
  { id: 1, name: "Anne" },
  { id: 2, name: "Baba" },
  { id: 3, name: "Abi/Abla" },
  { id: 4, name: "Eşi" },
  { id: 5, name: "Amca/Dayı" },
  { id: 6, name: "Hala/Teyze" },
  { id: 7, name: "Büyükanne" },
  { id: 8, name: "Büyükbaba" },
  { id: 9, name: "Diğer" },
]

export enum StudentOrderStateEnum {
  Draft = 1,
  PaymentScheduled,
}

export enum StudentOrderDetailStateEnum {
  Draft = 1,
  WillStudy,
  OnStudy,
  StudyCompleted,
}

export enum SaleStepEnum {
  Sale = 1,
  PaymentSchedule,
}

export const staticStudentOrderStateList: StudentOrderState[] = [
  {
    id: 1,
    name: "Taslak",
  },
  {
    id: 2,
    name: "Ödeme Planı Oluşturuldu",
  },
]

export enum Role {
  FounderManager,
  Personnel,
  GeneralManager,
  DeputyGeneralManager,
  Manager,
  DeputyManager,
  EducationCoordinator,
  PublicRelations,
  EducationConsultant,
  Advisor,
  Teacher,
  GuidanceCounselor,
  HumanResourcesManager,
  HumanResources,
  AccountingManager,
  Accounting,
  FinanceManager,
  Finance,
  CallCenterManager,
  CallCenter,
  Parent,
  Student,
}

export enum RoleCategory {
  FounderManager,
  Personnel,
  Parent,
  Student,
}

/////////////

export const staticSchoolTypeList: SchoolType[] = [
  {
    id: 1,
    name: "Anaokulu",
  },
  {
    id: 2,
    name: "İlkokul",
  },
  {
    id: 3,
    name: "Ortaokul",
  },
  {
    id: 4,
    name: "Lise",
  },
  {
    id: 5,
    name: "Üniversite",
  },
]

export enum SchoolTypeEnum {
  PreSchool = 1,
  PrimarySchool,
  MiddleSchool,
  HighSchool,
  University,
}

export enum ReportType {
  Receipt = 1,
  StudentList = 2,
  FinanceReport = 3,
  SchoolSaleContract = 4,
}

export enum ServiceTypeEnum {
  Education = 1,
  PublicationStationery,
  Food,
  Clothes,
  StudentShuttle,
  Other,
}

export const staticServiceTypeList: ServiceType[] = [
  { id: 1, name: "Eğitim" },
  { id: 2, name: "Yayın-Kırtasiye" },
  { id: 3, name: "Yemek" },
  { id: 4, name: "Kıyafet" },
  { id: 5, name: "Servis" },
  { id: 6, name: "Diğer" },
]
