enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_MENU = "menu",
  GET_WIDGET = "widget",
  REFRESH_TOKEN = "refreshToken",
  GET_ACCOUNT = "allWithUserType",
  SET_ACTIVE_ACCOUNT = "setActiveAccount",
  GET_PROFILE = "getProfile",

  FETCH_STUDENT_ORDERS = "fetchStudentOrders",
  FETCH_STUDENT_NOTES = "fetchStudentNotes",
  FETCH_STUDENT_PARENTS = "fetchStudentParents",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_MENU_LIST = "setMenuList",
  SET_WIDGET_LIST = "setWidgetList",
  SET_ACCOUNT_LIST = "getAccountWithUserTypeList",
  SET_PROFILE = "setProfile",

  SET_STUDENT_ORDERS = "setStudentOrders",
  SET_STUDENT_NOTES = "setStudentNotes",
  SET_STUDENT_PARENTS = "setStudentParents",
}

export { Actions, Mutations }
