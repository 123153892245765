import { Role } from "@/core/enums/enums"
import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import router from "@/router"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
export interface User {
  firstName: string
  lastName: string
  email: string
  password: string
  jwtToken: string
}

//TODO: should remove and get data from api
enum RoleTitle {
  "Kurucu/Yönetici",
  "Personel",
  "Genel Müdür",
  "Genel Müdür Yardımcısı",
  "Müdür",
  "Müdür Yardımcısı",
  "Eğitim Koordinatörü",
  "Halkla İlişkiler",
  "Eğitim Danışmanı",
  "Danışma",
  "Öğretmen",
  "Rehber Öğretmen",
  "İnsan Kaynakları Yöneticisi",
  "İnsan Kaynakları",
  "Muhasebe Yöneticisi",
  "Muhasebe",
  "Finans Yöneticisi",
  "Finans",
  "Çağrı Merkezi Yöneticisi",
  "Çağrı Merkezi",
  "Veli",
  "Öğrenci",
}

export class Account {
  id!: string
  title!: string
}

export class AccountRole {
  account!: Account
  usertype!: Role
  userTypeTitle!: string
  accountUserId!: string
  isSelected!: boolean
}
export interface UserAuthInfo {
  errors: unknown
  user: User
  isAuthenticated: boolean
  accountList: AccountRole[]
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {}
  user = {} as User
  isAuthenticated = !!JwtService.getToken()
  accountList: AccountRole[] = []

  public get activeAccount() {
    return this.accountList.find(x => x.isSelected)
  }

  get currentUser(): User {
    return this.user
  }

  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  get getErrors() {
    return this.errors
  }

  get getAccountList(): Array<AccountRole> {
    return this.accountList
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true
    this.user = user
    this.errors = {}
    JwtService.saveToken(user.jwtToken)
  }

  @Mutation
  [Mutations.SET_PROFILE](user) {
    this.user.firstName = user.firstName
    this.user.lastName = user.lastName
    this.user.email = user.email
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false
    this.user = {} as User
    this.errors = []
    this.accountList = []
    JwtService.destroyToken()
  }

  @Mutation
  [Mutations.SET_ACCOUNT_LIST](payload): void {
    this.accountList =
      payload.map(x => {
        x.RoleTitle = RoleTitle[x.userType]
        return x
      }) || []
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("auth/authenticate", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.message)
        throw new Error()
      })
  }

  @Action
  [Actions.GET_PROFILE](credentials) {
    return ApiService.get("user/me", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROFILE, data)
      })
      .catch(() => {
        throw new Error()
      })
  }

  @Action
  [Actions.REFRESH_TOKEN]() {
    return ApiService.post("auth/refresh-token", {})
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch()
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH)
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("account/add-trial-account", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.VERIFY_AUTH](route) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`auth/${encodeURIComponent(route)}/verify`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            router.push("/sign-in")
            this.context.commit(Mutations.PURGE_AUTH)
            reject(error)
          })
      })
    } else {
      router.push("/sign-in")
      this.context.commit(Mutations.PURGE_AUTH)
    }
  }

  @Action
  [Actions.GET_ACCOUNT]() {
    return ApiService.get("account/with-user-type")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ACCOUNT_LIST, data)
      })
      .catch(() => {
        //
      })
  }

  /**
   * set active account
   * @param accountId
   */
  @Action
  [Actions.SET_ACTIVE_ACCOUNT](accountId) {
    return ApiService.get(`account/set-active-account/${accountId}`).then(() => {
      //TODO: bildirim gitmeli
      console.log("account değiştirildi")
    })
  }
}
