
import { defineComponent, nextTick, onMounted } from "vue"
import { useStore } from "vuex"
import { Actions } from "@/store/enums/StoreEnums"
import { initializeComponents } from "@/core/plugins/keenthemes"
import BaseLoading from "@/components/BaseLoading.vue"
import trMessages from "devextreme/localization/messages/tr.json"
import { locale, loadMessages } from "devextreme/localization"

import tr from "element-plus/dist/locale/tr"
import "dayjs/locale/tr"
import { ElConfigProvider } from "element-plus"

export default defineComponent({
  name: "app",
  components: {
    ElConfigProvider,
    BaseLoading,
  },
  setup() {
    const store = useStore()
    onMounted(() => {
      if (store.getters.isUserAuthenticated) {
        store.dispatch(Actions.GET_MENU)
        store.dispatch(Actions.GET_ACCOUNT)
        store.dispatch(Actions.GET_PROFILE)
        store.dispatch(Actions.GET_WIDGET)
      }

      loadMessages(trMessages)
      locale("tr")

      nextTick(() => {
        initializeComponents()
      })
    })

    return {
      locale: tr,
    }
  },
})
