import { createApp } from "vue"
import App from "./App.vue"

/*
TIP: To get started with clean router change path to @/router/clean.ts...
 */
import router from "./router"
import store from "./store"
import ElementPlus from "element-plus"
//import tr from "element-plus/es/locale/lang/tr"

import i18n from "@/core/plugins/i18n"

import "devextreme/dist/css/dx.light.css"

//imports for app initialization
import ApiService from "@/core/services/ApiService"
import { initInlineSvg } from "@/core/plugins/inline-svg"

import "bootstrap"
import { initElIcons } from "./core/plugins/el-icons"
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"

const app = createApp(App)
app.component("QuillEditor", QuillEditor)
app.use(store)
app.use(router)
app.use(ElementPlus)

ApiService.init(app, store)
initInlineSvg(app)
initElIcons(app)
app.use(i18n)

app.mount("#app")

document.addEventListener(
  "touchstart",
  args => {
    console.log("document.addEventListener", args)
  },
  { passive: true }
)
