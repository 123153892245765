import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homepage",
    redirect: "/dashboard/summary",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        name: "dashboard-summary",
        path: "/dashboard/summary",
        component: () => import("@/views/general/dashboard/Manager.vue"),
        meta: { title: "Özet", rootPath: "/dashboard/summary" },
      },
      {
        name: "menu-user",
        path: "/permission/menu",
        component: () => import("@/views/general/permission/UserMenu.vue"),
        meta: { title: "Menü yetki tanımlama", rootPath: "/permission/menu" },
      },
      {
        name: "widget-user",
        path: "/permission/widget",
        component: () => import("@/views/general/permission/UserWidget.vue"),
        meta: { title: "Widget yetki tanımlama", rootPath: "/permission/widget" },
      },
      {
        name: "user-action",
        path: "/permission/action",
        component: () => import("@/views/general/permission/UserAction.vue"),
        meta: { title: "Şube eylem yetkilendirme", rootPath: "/general/business" },
      },
      {
        name: "menu-action",
        path: "/developer/menu-action",
        component: () => import("@/views/general/permission/MenuAction.vue"),
        meta: { title: "Menü eylem tanımalama", rootPath: "/developer/menu-action" },
      },
      {
        name: "account-add",
        path: "/admin/account/add",
        component: () => import("@/views/general/definitions/account/list.vue"),
        meta: { title: "Hesap Ekle", rootPath: "/admin/account" },
        children: [],
        props: { add: true },
      },
      {
        name: "account-edit",
        path: "/admin/account/:account_id/edit",
        component: () => import("@/views/general/definitions/account/list.vue"),
        meta: { title: "Hesap Düzenle", rootPath: "/admin/account" },
        children: [],
      },
      {
        name: "account-list",
        path: "/admin/account",
        component: () => import("@/views/general/definitions/account/list.vue"),
        meta: { title: "Hesap Listele", rootPath: "/admin/account" },
        children: [],
      },
      {
        name: "license-add",
        path: "/general/account/:account_id/license/add",
        component: () => import("@/views/general/definitions/account/license/list.vue"),
        meta: { title: "Lisans Ekle", rootPath: "/general/account" },
        children: [],
        props: { add: true },
      },
      {
        name: "license-edit",
        path: "/general/account/:account_id/license/:license_id",
        component: () => import("@/views/general/definitions/account/license/list.vue"),
        meta: { title: "Lisans Düzelt", rootPath: "/general/account" },
        children: [],
      },
      {
        name: "license-list",
        path: "/general/account/:account_id/license",
        component: () => import("@/views/general/definitions/account/license/list.vue"),
        meta: { title: "Lisans Listele", rootPath: "/general/account" },
        children: [],
      },
      {
        name: "business-edit",
        path: "/general/business/:business_id/edit",
        component: () => import("@/views/general/definitions/business/list.vue"),
        meta: { title: "İşletme Düzenle", rootPath: "/general/business" },
        children: [],
      },
      {
        name: "business-list",
        path: "/general/business",
        component: () => import("@/views/general/definitions/business/list.vue"),
        meta: { title: "İşletme Listele", rootPath: "/general/business" },
        children: [],
      },
      {
        name: "admin-business-add",
        path: "/admin/business/add",
        component: () => import("@/views/admin/definitions/business/list.vue"),
        meta: { title: "İşletme Ekle", rootPath: "/admin/business" },
        children: [],
        props: { add: true },
      },
      {
        name: "admin-business-edit",
        path: "/admin/business/:business_id/edit",
        component: () => import("@/views/admin/definitions/business/list.vue"),
        meta: { title: "İşletme Düzenle", rootPath: "/admin/business" },
        children: [],
      },
      {
        name: "admin-business-list",
        path: "/admin/business",
        component: () => import("@/views/admin/definitions/business/list.vue"),
        meta: { title: "İşletme Listele", rootPath: "/admin/business" },
        children: [],
      },
      {
        name: "corporation-add",
        path: "/general/corporate/add",
        component: () => import("@/views/general/definitions/corporation/list.vue"),
        meta: { title: "Şirket Ekle", rootPath: "/general/corporate" },
        children: [],
        props: { add: true },
      },
      {
        name: "corporation-edit",
        path: "/general/corporate/:corporation_id/edit",
        component: () => import("@/views/general/definitions/corporation/list.vue"),
        meta: { title: "Şirket Düzenle", rootPath: "/general/corporate" },
        children: [],
      },
      {
        name: "corporation-list",
        path: "/general/corporate",
        component: () => import("@/views/general/definitions/corporation/list.vue"),
        meta: { title: "Şirket Listele", rootPath: "/general/corporate" },
        children: [],
      },
      {
        name: "admin-corporation-add",
        path: "/admin/corporate/add",
        component: () => import("@/views/admin/definitions/corporation/list.vue"),
        meta: { title: "Şirket Ekle", rootPath: "/admin/corporate" },
        children: [],
        props: { add: true },
      },
      {
        name: "admin-corporation-edit",
        path: "/admin/corporate/:corporation_id/edit",
        component: () => import("@/views/admin/definitions/corporation/list.vue"),
        meta: { title: "Şirket Düzenle", rootPath: "/admin/corporate" },
        children: [],
      },
      {
        name: "admin-corporation-list",
        path: "/admin/corporate",
        component: () => import("@/views/admin/definitions/corporation/list.vue"),
        meta: { title: "Şirket Listele", rootPath: "/admin/corporate" },
        children: [],
      },
      {
        name: "branch-add",
        path: "/general/branch/add",
        component: () => import("@/views/general/definitions/branch/list.vue"),
        meta: { title: "Şube Ekle", rootPath: "/general/branch" },
        children: [],
        props: { add: true },
      },
      {
        name: "branch-edit",
        path: "/general/branch/:branch_id/edit",
        component: () => import("@/views/general/definitions/branch/list.vue"),
        meta: { title: "Şube Düzenle", rootPath: "/general/branch" },
        children: [],
      },
      {
        name: "branch-list",
        path: "/general/branch",
        component: () => import("@/views/general/definitions/branch/list.vue"),
        meta: { title: "Şube Listele", rootPath: "/general/branch" },
        children: [],
      },
      {
        name: "admin-branch-add",
        path: "/admin/branch/add",
        component: () => import("@/views/admin/definitions/branch/list.vue"),
        meta: { title: "Şube Ekle", rootPath: "/admin/branch" },
        children: [],
        props: { add: true },
      },
      {
        name: "admin-branch-edit",
        path: "/admin/branch/:branch_id/edit",
        component: () => import("@/views/admin/definitions/branch/list.vue"),
        meta: { title: "Şube Düzenle", rootPath: "/admin/branch" },
        children: [],
      },
      {
        name: "admin-branch-list",
        path: "/admin/branch",
        component: () => import("@/views/admin/definitions/branch/list.vue"),
        meta: { title: "Şube Listele", rootPath: "/admin/branch" },
        children: [],
      },
      {
        name: "user-edit",
        path: "/general/user/:user_id",
        component: () => import("@/views/general/definitions/user/list.vue"),
        meta: { title: "Kullanıcı Düzenle", rootPath: "/general/user" },
        children: [],
      },
      {
        name: "user-add",
        path: "/general/user/add",
        component: () => import("@/views/general/definitions/user/list.vue"),
        meta: { title: "Kullanıcı Ekle", rootPath: "/general/user" },
        children: [],
        props: { add: true },
      },
      {
        name: "user-list",
        path: "/general/user",
        component: () => import("@/views/general/definitions/user/list.vue"),
        meta: { title: "Kullanıcı Listele", rootPath: "/general/user" },
        children: [],
      },
      {
        name: "semester-edit",
        path: "/education/semester/:semester_id",
        component: () => import("@/views/education/definitions/semester/list.vue"),
        meta: { title: "Dönem Düzenle", rootPath: "/education/semester" },
        children: [],
      },
      {
        name: "semester-add",
        path: "/education/semester/add",
        component: () => import("@/views/education/definitions/semester/list.vue"),
        meta: { title: "Dönem Ekle", rootPath: "/education/semester" },
        props: { add: true },
      },
      {
        name: "semester-list",
        path: "/education/semester",
        component: () => import("@/views/education/definitions/semester/list.vue"),
        meta: { title: "Dönem Listele", rootPath: "/education/semester" },
        children: [],
      },
      {
        name: "branch-group-edit",
        path: "/general/branch-group/:branch_group_id",
        component: () => import("@/views/general/definitions/branch-group/list.vue"),
        meta: { title: "Bölge Düzenle", rootPath: "/general/branch-group" },
        children: [],
      },
      {
        name: "branch-group-add",
        path: "/general/branch-group/add",
        component: () => import("@/views/general/definitions/branch-group/list.vue"),
        meta: { title: "Bölge Ekle", rootPath: "/general/branch-group" },
        props: { add: true },
      },
      {
        name: "branch-group-list",
        path: "/general/branch-group",
        component: () => import("@/views/general/definitions/branch-group/list.vue"),
        meta: { title: "Bölge Listele", rootPath: "/general/branch-group" },
        children: [],
      },
      {
        name: "time-period-edit",
        path: "/education/time-period/:time_period_id",
        component: () => import("@/views/education/definitions/time-period/list.vue"),
        meta: { title: "Zaman Düzenle", rootPath: "/education/time-period" },
        children: [],
      },
      {
        name: "time-period-add",
        path: "/education/time-period/add",
        component: () => import("@/views/education/definitions/time-period/list.vue"),
        meta: { title: "Zaman Ekle", rootPath: "/education/time-period" },
        props: { add: true },
      },
      {
        name: "time-period-list",
        path: "/education/time-period",
        component: () => import("@/views/education/definitions/time-period/list.vue"),
        meta: { title: "Zaman Listele", rootPath: "/education/time-period" },
        children: [],
      },
      {
        name: "class-room-edit",
        path: "/education/class-room/:classroom_id",
        component: () => import("@/views/education/definitions/class-room/list.vue"),
        meta: { title: "Derslik Düzenle", rootPath: "/education/class-room" },
        children: [],
      },
      {
        name: "class-room-add",
        path: "/education/class-room/add",
        component: () => import("@/views/education/definitions/class-room/list.vue"),
        meta: { title: "Derslik Ekle", rootPath: "/education/class-room" },
        props: { add: true },
      },
      {
        name: "class-room-list",
        path: "/education/class-room",
        component: () => import("@/views/education/definitions/class-room/list.vue"),
        meta: { title: "Derslik Listele", rootPath: "/education/class-room" },
        children: [],
      },
      {
        name: "class-student-list",
        path: "/education/student/class",
        component: () => import("@/views/education/definitions/class-student/list.vue"),
        meta: { title: "Sınıf Atama", rootPath: "/education/student/class" },
        children: [],
      },
      {
        name: "class-student-assign",
        path: "/education/student/class/:school_class_id",
        component: () => import("@/views/education/definitions/class-student/list.vue"),
        meta: { title: "Öğrenci Listele", rootPath: "/education/student/class" },
        children: [],
      },
      {
        name: "class-student-closure-list",
        path: "/education/student/class-closure",
        component: () => import("@/views/education/definitions/class-student-closure/list.vue"),
        meta: { title: "Sınıf Kapama", rootPath: "/education/student/class-closure" },
        children: [],
      },
      {
        name: "class-student-closure-close",
        path: "/education/student/class-closure/:school_class_id",
        component: () => import("@/views/education/definitions/class-student-closure/list.vue"),
        meta: { title: "Öğrenci Listele", rootPath: "/education/student/class-closure" },
        children: [],
      },
      {
        name: "school-class-edit",
        path: "/education/school-class/:school_class_id",
        component: () => import("@/views/education/definitions/school-class/list.vue"),
        meta: { title: "Sınıf Düzenle", rootPath: "/education/school-class" },
        children: [],
      },
      {
        name: "school-class-add",
        path: "/education/school-class/add",
        component: () => import("@/views/education/definitions/school-class/list.vue"),
        meta: { title: "Sınıf Ekle", rootPath: "/education/school-class" },
        children: [],
        props: { add: true },
      },
      {
        name: "school-class-list",
        path: "/education/school-class",
        component: () => import("@/views/education/definitions/school-class/list.vue"),
        meta: { title: "Sınıf Listele", rootPath: "/education/school-class" },
        children: [],
      },
      {
        name: "service-price-list",
        path: "/education/service-price",
        component: () => import("@/views/education/definitions/service-price/list.vue"),
        meta: { title: "Satış Türü - Fiyat", rootPath: "/education/service-price" },
        children: [],
      },
      {
        name: "service-price-edit",
        path: "/education/service-price/:service_price_id",
        component: () => import("@/views/education/definitions/service-price/list.vue"),
        meta: { title: "Satış Türü - Fiyat", rootPath: "/education/service-price" },
        children: [],
      },
      {
        name: "service-list",
        path: "/education/service",
        component: () => import("@/views/education/definitions/service/list.vue"),
        meta: { title: "Satış Türü", rootPath: "/education/service" },
        children: [],
      },
      {
        name: "service-edit",
        path: "/education/service/:service_id",
        component: () => import("@/views/education/definitions/service/list.vue"),
        meta: { title: "Satış Türü Düzenle", rootPath: "/education/service" },
        children: [],
      },
      {
        name: "service-add",
        path: "/education/service/add",
        component: () => import("@/views/education/definitions/service/list.vue"),
        meta: { title: "Satış Türü Ekle", rootPath: "/education/service" },
        props: { add: true },
      },
      {
        name: "account-card-type-list",
        path: "/finance/account-card-type",
        component: () => import("@/views/finance/definitions/accountcard-type/list.vue"),
        meta: { title: "Cari Türü", rootPath: "/finance/account-card-type" },
      },
      {
        name: "account-card-type-add",
        path: "/finance/account-card-type/add",
        component: () => import("@/views/finance/definitions/accountcard-type/list.vue"),
        meta: { title: "Cari Türü Ekle", rootPath: "/finance/account-card-type" },
        props: { add: true },
      },
      {
        name: "account-card-type-edit",
        path: "/finance/account-card-type/:account_card_type_id",
        component: () => import("@/views/finance/definitions/accountcard-type/list.vue"),
        meta: { title: "Cari Türü Düzenle", rootPath: "/finance/account-card-type" },
      },
      {
        name: "expense-type-list",
        path: "/finance/expense-type",
        component: () => import("@/views/finance/definitions/expense-type/list.vue"),
        meta: { title: "Gider Türü", rootPath: "/finance/expense" },
      },
      {
        name: "expense-type-add",
        path: "/finance/expense-type/add",
        component: () => import("@/views/finance/definitions/expense-type/list.vue"),
        meta: { title: "Gider Türü Ekle", rootPath: "/finance/expense" },
        props: { add: true },
      },
      {
        name: "expense-type-edit",
        path: "/finance/expense-type/:expense_type_id",
        component: () => import("@/views/finance/definitions/expense-type/list.vue"),
        meta: { title: "Gider Türü Düzenle", rootPath: "/finance/expense-type" },
      },
      {
        name: "revenue-type-list",
        path: "/finance/revenue-type",
        component: () => import("@/views/finance/definitions/revenue-type/list.vue"),
        meta: { title: "Gelir Türü", rootPath: "/finance/revenue-type" },
      },
      {
        name: "revenue-type-add",
        path: "/finance/revenue-type/add",
        component: () => import("@/views/finance/definitions/revenue-type/list.vue"),
        meta: { title: "Gelir Türü Ekle", rootPath: "/finance/revenue" },
        props: { add: true },
      },
      {
        name: "revenue-type-edit",
        path: "/finance/revenue-type/:revenue_type_id",
        component: () => import("@/views/finance/definitions/revenue-type/list.vue"),
        meta: { title: "Gelir Türü Düzenle", rootPath: "/finance/revenue-type" },
      },
      {
        name: "cash-book-list",
        path: "/finance/cash-book",
        component: () => import("@/views/finance/definitions/cash-book/list.vue"),
        meta: { title: "Nakit-Pos-Banka Hesabı", rootPath: "/finance/cash-book" },
      },
      {
        name: "cash-book-add",
        path: "/finance/cash-book/add",
        component: () => import("@/views/finance/definitions/cash-book/list.vue"),
        meta: { title: "Nakit-Pos-Banka Hesabı Ekle", rootPath: "/finance/cash-book" },
        props: { add: true },
      },
      {
        name: "cash-book-edit",
        path: "/finance/cash-book/:cash_book_id",
        component: () => import("@/views/finance/definitions/cash-book/list.vue"),
        meta: { title: "Nakit-Pos-Banka Hesabı Düzenle", rootPath: "/finance/cash-book" },
      },
      {
        name: "account-virman",
        path: "/finance/account-virman",
        component: () => import("@/views/finance/transfers/account-virman/account-virman.vue"),
        meta: { title: "Hesaplar Arası Transfer", rootPath: "/finance/account-virman" },
      },
      {
        name: "branch-virman",
        path: "/finance/branch-virman",
        component: () => import("@/views/finance/transfers/branch-virman/branch-virman.vue"),
        meta: { title: "Şubeler Arası Transfer", rootPath: "/finance/branch-virman" },
      },
      {
        name: "checkout",
        path: "/finance/checkout",
        component: () => import("@/views/finance/transfers/checkout/checkout.vue"),
        meta: { title: "Ana Kasa Para Çıkışı", rootPath: "/finance/checkout" },
      },
      {
        name: "cheque-list",
        path: "/finance/cheque",
        component: () => import("@/views/finance/transfers/cheque/list.vue"),
        meta: { title: "Çek Listesi", rootPath: "/finance/cheque" },
        children: [
          {
            name: "cheque-detail",
            path: "/finance/cheque/:cheque_id",
            component: () => import("@/components/finance/cheque/cheque.vue"),
            meta: { title: "Çek Detay", rootPath: "/finance/cheque", chequeDetail: true },
          },
        ],
      },
      {
        name: "new-student",
        path: "/education/student/new",
        component: () => import("@/views/education/student/new.vue"),
        meta: { title: "Yeni Öğrenci", rootPath: "/education/student" },
      },
      {
        name: "search-student",
        path: "/education/student/search",
        component: () => import("@/views/education/student/search.vue"),
        meta: { title: "Öğrenci Ara", rootPath: "/education/student" },
      },
      {
        name: "student-summary",
        path: "/education/student/:student_id",
        component: () => import("@/views/education/student/summary.vue"),
        meta: { title: "Öğrenci Detay", rootPath: "/education/student" },
        children: [
          {
            name: "student-general",
            path: "/education/student/:student_id/general",
            component: () => import("@/components/education/student/general.vue"),
            meta: {
              studentTab: "student-general",
              title: "Genel",
              rootPath: "/education/student",
            },
          },
          {
            name: "student-contact",
            path: "/education/student/:student_id/contact",
            component: () => import("@/components/education/student/contact.vue"),
            meta: {
              studentTab: "student-contact",
              title: "İletişim",
              rootPath: "/education/student",
            },
          },
          {
            name: "student-parent",
            path: "/education/student/:student_id/parent",
            component: () => import("@/components/education/student/parent.vue"),
            meta: {
              studentTab: "student-parent",
              title: "Veli",
              rootPath: "/education/student",
            },
          },
          {
            name: "student-sale",
            path: "/education/student/:student_id/sale",
            component: () => import("@/components/education/student/sale.vue"),
            meta: {
              studentTab: "student-sale",
              title: "Satışlar",
              rootPath: "/education/student",
            },
            children: [
              {
                name: "student-sale-detail",
                path: "/education/student/:student_id/sale/:student_order_id",
                component: () => import("@/components/education/student/sale.vue"),
                meta: {
                  title: "Öğrenci Satış Detayı",
                  rootPath: "/education/student",
                  editSale: true,
                },
              },
              {
                name: "student-sale-add",
                path: "/education/student/:student_id/sale/add",
                component: () => import("@/components/education/student/sale.vue"),
                meta: {
                  title: "Öğrenci Satış Ekle",
                  rootPath: "/education/student",
                  addSale: true,
                },
              },
              {
                name: "student-payment",
                path: "/education/student/:student_id/sale/:student_order_id/payment",
                component: () => import("@/components/education/student/sale.vue"),
                meta: {
                  title: "Öğrenci Satış - Ödeme",
                  rootPath: "/education/student",
                  salePayment: true,
                },
              },
              {
                name: "student-invoice",
                path: "/education/student/:student_id/sale/:student_order_id/invoice",
                component: () => import("@/components/education/student/sale.vue"),
                meta: {
                  title: "Öğrenci Satış - Fatura",
                  rootPath: "/education/student",
                  saleInvoice: true,
                },
              },
            ],
          },
          {
            name: "student-note",
            path: "/education/student/:student_id/note",
            component: () => import("@/components/education/student/note.vue"),
            meta: {
              studentTab: "student-note",
              title: "Notlar",
              rootPath: "/education/student",
            },
          },
        ],
      },
      {
        name: "account-card-list",
        path: "/finance/account-card",
        component: () => import("@/views/finance/definitions/account-card/list.vue"),
        meta: { title: "Cariler", rootPath: "/finance/account-card" },
      },
      {
        name: "account-card-add",
        path: "/finance/account-card/add",
        component: () => import("@/views/finance/definitions/account-card/list.vue"),
        meta: { title: "Cari Ekle", rootPath: "/finance/account-card" },
        props: { add: true },
      },
      {
        name: "account-card-edit",
        path: "/finance/account-card/:account_card_id",
        component: () => import("@/views/finance/definitions/account-card/list.vue"),
        meta: { title: "Cari Düzenle", rootPath: "/finance/account-card" },
      },
      {
        name: "expense",
        path: "/finance/expense",
        component: () => import("@/views/finance/transfers/expense/expense.vue"),
        meta: { title: "Gider Fişi", rootPath: "/finance/expense" },
      },
      {
        name: "revenue",
        path: "/finance/revenue",
        component: () => import("@/views/finance/transfers/revenue/revenue.vue"),
        meta: { title: "Gelir Fişi", rootPath: "/finance/revenue" },
      },
      {
        name: "payment",
        path: "/finance/payment",
        component: () => import("@/views/finance/transfers/payment/payment.vue"),
        meta: { title: "Ödeme", rootPath: "/finance/payment" },
      },
      {
        name: "payment-detail",
        path: "/finance/payment/:invoice_id",
        component: () => import("@/views/finance/transfers/payment/payment.vue"),
        meta: { title: "Ödeme", rootPath: "/finance/payment" },
      },
      {
        name: "collection",
        path: "/finance/collection",
        component: () => import("@/views/finance/transfers/collection/collection.vue"),
        meta: { title: "Tahsilat", rootPath: "/finance/collection" },
      },
      {
        name: "collection-detail",
        path: "/finance/collection/:invoice_id",
        component: () => import("@/views/finance/transfers/collection/collection.vue"),
        meta: { title: "Tahsilat", rootPath: "/finance/collection" },
      },
      {
        name: "pos-cash-out",
        path: "/finance/report/pos-cash-out",
        component: () => import("@/views/finance/report/pos-cash-out/pos-cash-out.vue"),
        meta: { title: "Gün Sonu Raporu", rootPath: "/finance/report/pos-cash-out" },
      },
      {
        name: "finance-revenue-enrollment",
        path: "/finance/report/revenue-enrollment",
        component: () => import("@/views/finance/report/revenue-enrollment/revenue-enrollment.vue"),
        meta: { title: "Ciro-Kayıt Raporu", rootPath: "/finance/report/revenue-enrollment" },
      },
      {
        name: "finance-case",
        path: "/finance/report/case",
        component: () => import("@/views/finance/report/case/case.vue"),
        meta: { title: "Kasa Detay", rootPath: "/finance/report/case" },
      },
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        meta: { anonymous: true },
        component: () => import("@/views/auth/Error404.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
        meta: { anonymous: true },
        component: () => import("@/views/auth/Error404.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () => import("@/views/auth/SignUp.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () => import("@/views/auth/PasswordReset.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/new-password/:id",
        name: "new-password",
        component: () => import("@/views/auth/CreatePassword.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/invitation/:invitation_code/:has_register",
        name: "invitation",
        component: () => import("@/views/auth/Invitation.vue"),
        meta: { anonymous: true },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profile",
        name: "my-profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/messages",
        name: "messages",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: { anonymous: true },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/components/page-layouts/Profile.vue"),
        meta: { anonymous: true },
      },
    ],
  },
  {
    path: "/v/:id",
    name: "verify",
    component: () => import("@/components/page-layouts/Verify.vue"),
    meta: { anonymous: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(to => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  if (!to.meta.anonymous) {
    store.dispatch(Actions.VERIFY_AUTH, to.meta.rootPath)
  }

  document.title = `${to.meta.title == undefined ? "" : to.meta.title + " | "}Edutime.app`

  // Scroll page to top on every route change
  //setTimeout(() => {
  //  window.scrollTo(0, 0)
  //}, 100)
})

export default router
