import { StudentParent } from "./../../core/models/StudentParent"
import { StudentOrderStateEnum } from "./../../core/enums/enums"
import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { StudentNote } from "@/core/models/StudentNote"
import { StudentOrderSummary } from "@/core/models/StudentOrderSummary"

export interface IStudentModule {
  studentOrderList: StudentOrderSummary[]
  studentNoteList: StudentNote[]
  studentParentList: StudentParent[]
}

@Module
export default class StudentModule extends VuexModule implements IStudentModule {
  studentOrderList = [] as StudentOrderSummary[]
  studentNoteList = [] as StudentNote[]
  studentParentList = [] as StudentParent[]

  get getStudentOrderList() {
    return this.studentOrderList
  }

  get getStudentOrderHasNotOrderTrackerList() {
    return this.studentOrderList.filter(
      x => !x.hasInvoiceTracker && x.state != StudentOrderStateEnum.Draft
    )
  }

  get getStudentOrderPaymentScheduledList() {
    return this.studentOrderList.filter(x => x.state != StudentOrderStateEnum.Draft)
  }

  get getStudentNoteList() {
    return this.studentNoteList
  }

  get getStudentPinnedNoteList() {
    return this.studentNoteList.filter(x => x.isPinned)
  }

  get getStudentPopupNoteList() {
    return this.studentNoteList.filter(x => x.isPopup)
  }

  get getStudentParentList() {
    return this.studentParentList
  }

  @Mutation
  [Mutations.SET_STUDENT_ORDERS](orders) {
    this.studentOrderList = orders
  }

  @Mutation
  [Mutations.SET_STUDENT_NOTES](notes) {
    this.studentNoteList = notes
  }

  @Mutation
  [Mutations.SET_STUDENT_PARENTS](notes) {
    this.studentParentList = notes
  }

  @Action
  [Actions.FETCH_STUDENT_NOTES](studentId) {
    return ApiService.get("student-note/student/" + studentId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STUDENT_NOTES, data)
      })
      .catch(response => {
        throw new Error(response)
      })
  }

  @Action
  [Actions.FETCH_STUDENT_ORDERS](studentId) {
    return ApiService.get("student-order/student/" + studentId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STUDENT_ORDERS, data)
      })
      .catch(response => {
        throw new Error(response)
      })
  }

  @Action
  [Actions.FETCH_STUDENT_PARENTS](studentId) {
    return ApiService.get("student/" + studentId + "/parent")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STUDENT_PARENTS, data)
      })
      .catch(response => {
        throw new Error(response)
      })
  }
}
