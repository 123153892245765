import { App } from "vue"
import * as ElementPlusIconsVue from "@element-plus/icons-vue"

/**
 * Initialize Inline-Svg component
 * @param app vue instance
 */
export function initElIcons(app: App<Element>) {
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
}
